import React from "react";
import PropTypes from "prop-types";

import CampaignCard from "components/CampaignCard";
import CampaignListing from "components/CampaignListing";
import LinkButton from "components/LinkButton";

import "./_style.scss";

const InstantSearch = ({
  campaigns,
  hasSearched,
  id,
  isError,
  isLoading,
  onQueryChange,
  onQueryKeyDown,
  queryFiltered,
  queryInput,
  resultCount,
  ...other
}) => (
  <div className="instant-search" {...other}>
    <input className="instant-search__checkbox" id={id} type="checkbox" autoComplete="off" />
    <div className="instant-search__overlay">
      <label className="instant-search__overlay-toggle" htmlFor="search-toggle-checkbox">
        <span className="instant-search__overlay-toggle-text">Close search</span>
      </label>
    </div>
    <div className="instant-search__container">
      <div className="instant-search__header">
        <div className="instant-search__header-inner">
          <div className="instant-search__input-container">
            <input
              className="instant-search__input"
              id="js-instant-search-input"
              data-testid="js-instant-search-input"
              onChange={(e) => onQueryChange(e.target.value)}
              onKeyDown={(e) => onQueryKeyDown(e)}
              placeholder="Search for campaigns or causes"
              type="search"
              value={queryInput}
            />
            {isLoading === true
                && (
                <div className="instant-search__input-spinner">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                )}
          </div>
          <label className="instant-search__container-close" htmlFor={id}>
            &times;
          </label>
        </div>
      </div>
      <div className="instant-search__results">
        {queryFiltered.length > 0
            && (
            <div className="instant-search__results-block">
              {isError === true
                && (
                <div className="instant-search__message">
                  There was an issue retrieving your search results. Please try again later, or
                  take a look some
                  {" "}
                  <a href="/discover">popular crowdfunding campaigns</a>
                  {" "}
                  that
                  are currently running.
                </div>
                )}
              {(isError === false && hasSearched === true)
                && <div className="instant-search__results-heading">Campaigns</div>}
              {(isError === false && campaigns.length > 0)
                && (
                <CampaignListing
                  isLoading={isLoading}
                  isRow4
                >
                  {
                    campaigns.slice(0, 4).map((campaign, i) => (
                      <CampaignCard
                        key={`${i}-${campaign.id}}`}
                        campaign={campaign}
                      />
                    ))
                  }
                </CampaignListing>
                )}
              {(isError === false && hasSearched === true && campaigns.length === 0)
                && (
                <div className="instant-search__message">
                  Unfortunately we couldn't find any campaigns that matched your search query.
                  Perhaps try another search keyword? Or take a look at our
                  most
                  {" "}
                  <a href="/discover">popular crowdfunding campaigns</a>
                  {" "}
                  that
                  are currently running.
                </div>
                )}
              {isError === false && campaigns.length > 0
                && (
                <div className="instant-search__action">
                  <div className="instant-search__action-cta">
                    <LinkButton
                      href={`/search/${queryFiltered}`}
                      isFullWidth
                      look="secondary-outline"
                    >
                      See all
                      {" "}
                      {resultCount}
                      {" "}
                      results
                    </LinkButton>
                  </div>
                </div>
                )}
            </div>
            )}
      </div>
    </div>
  </div>
);

InstantSearch.propTypes = {
  campaigns: PropTypes.array.isRequired,
  hasSearched: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onQueryChange: PropTypes.func.isRequired,
  onQueryKeyDown: PropTypes.func.isRequired,
  queryFiltered: PropTypes.string.isRequired,
  queryInput: PropTypes.string.isRequired,
  resultCount: PropTypes.number.isRequired,
};

export default InstantSearch;
