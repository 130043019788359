import React from "react";
import { fetch as router } from "helpers/router";
import ChuffedLogo from "components/ChuffedLogo";
import LanguageProvider from "components/LanguageProvider/LanguageProvider";
import useGetTranslationFiles from "components/LanguageProvider/utils/getTranslationFiles";
import DashboardNav from "./templates/DashboardNav";
import useAuthViewer from "../../hooks/useAuthViewer";
import ClientNav from "./templates/ClientNav";

const renderNavLinkItem = (label, url, onClick = false) => (
  <li className="navigation__list-item">
    <a
      className="navigation__item-link"
      data-test="navigation__item-link"
      href={url}
      onClick={onClick || undefined}
    >
      {label}
    </a>
  </li>
);

const profileAnchor = (profile) => {
  if (!profile) {
    return null;
  }

  const profileImage = profile.image ?? "/images/avatar-default.svg";
  const fullName = `${profile.first_name} ${profile.last_name}`;

  return (
    <a
      className="avatar --size-xsmall"
      href={router("web.dashboard")}
      title={fullName}
      style={{ backgroundImage: `url(${encodeURI(profileImage)})` }}
    >
      <img
        className="avatar__image"
        src={profileImage}
        alt={profile.first_name}
      />
    </a>
  );
};

const renderSection = (viewer) => {
  const isLoggedIn = viewer !== null;
  const isDashboard = ["dashboard", "edit"].includes(window.location.pathname.split("/")[1].toLowerCase());

  if (isLoggedIn && isDashboard) {
    return (
      <DashboardNav
        me={viewer}
        profileAnchor={profileAnchor}
        renderNavLinkItem={renderNavLinkItem}
        router={router}
      />
    );
  }
  return (
    <ClientNav
      me={viewer}
      profileAnchor={profileAnchor}
      renderNavLinkItem={renderNavLinkItem}
      router={router}
      isLoggedIn={isLoggedIn}
    />
  );
};

export const Navigation = () => {
  const { viewer, loading } = useAuthViewer();
  const { translations, filesLoading } = useGetTranslationFiles();

  if (filesLoading) {
    return null;
  }

  return (
    <div className="header__container">
      <div className="header__section --has-items">
        <a className="header__logo-link" href="/" data-test="header-logo__link">
          <ChuffedLogo />
        </a>
      </div>
      <LanguageProvider translations={translations}>
        {!loading && renderSection(viewer)}
      </LanguageProvider>
    </div>
  );
};

export default Navigation;
