import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import "./_style.scss";

const TextHeading = ({
  children,
  accent,
  align,
  level,
  light,
  look,
  marginBottom,
  marginTop,
  size,
  uppercase,
  ...other
}) => {
  const Element = level === 0 ? "div" : `h${level}`;

  return (
    <Element
      className={
        classNames({
          "text-heading": true,
          "--align-left": align === "left",
          "--align-center": align === "center",
          "--align-right": align === "right",
          "--accent": accent,
          "--light": light,
          "--brand": look === "brand",
          "--subdued": look === "subdued",
          "--inverse": look === "inverse",
          [`--margin-bottom-${marginBottom}`]: marginBottom,
          [`--margin-top-${marginTop}`]: marginTop,
          [`--size-${size}`]: size,
          "--uppercase": uppercase,
        })
      }
      {...other}
    >
      {children}
    </Element>
  );
};

TextHeading.propTypes = {
  children: PropTypes.node.isRequired,
  accent: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center", "right"]),
  level: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8]),
  light: PropTypes.bool,
  look: PropTypes.oneOf(["default", "brand", "subdued", "inverse"]),
  marginBottom: PropTypes.oneOf(["none", "half", "single", "double"]),
  marginTop: PropTypes.oneOf(["none", "half", "single", "double"]),
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  uppercase: PropTypes.bool,
};

TextHeading.defaultProps = {
  level: 2,
  size: 2,
};

export default TextHeading;
