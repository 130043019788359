import React from "react";
import { useApolloClient } from "@apollo/client";
import LOGOUT from "../queries/logout.gql";

const DashboardNav = (props) => {
  const client = useApolloClient();
  const me = props.me;
  const profileAnchor = props.profileAnchor;
  const renderNavLinkItem = props.renderNavLinkItem;
  const router = props.router;

  const logoutIcon = (
    <svg width="12" height="15" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero">
        <path d="M6.5 7C8.435 7 10 5.435 10 3.5S8.435 0 6.5 0 3 1.565 3 3.5 4.565 7 6.5 7zm0-5.765c1.256 0 2.265 1.01 2.265 2.265 0 1.256-1.01 2.265-2.265 2.265-1.256 0-2.265-1.01-2.265-2.265 0-1.256 1.01-2.265 2.265-2.265zM1.052 15h9.917c.278 0 .556-.127.75-.318.192-.212.3-.488.277-.785C11.762 10.545 9.19 8 6 8 2.81 8 .238 10.524.003 13.897c-.022.297.085.573.278.785.215.212.494.318.772.318zm4.97-5.727c2.42 0 4.412 1.888 4.69 4.454h-9.38c.277-2.545 2.27-4.454 4.69-4.454z" />
      </g>
    </svg>
  );

  const logout = async (e) => {
    e.preventDefault();
    await client.mutate({ mutation: LOGOUT });
    window.location.href = router("auth");
  };

  return (

    <div className="header__section --has-items --grows-desktop">
      <div className="header__user">
        { profileAnchor(me) }
      </div>
      <div className="navigation">
        <input
          type="checkbox"
          className="navigation__checkbox"
          id="navigation-checkbox"
          autoComplete="off"
          value="on"
        />

        <div className="navigation__overlay">
          <label
            className="navigation__overlay-toggle"
            htmlFor="navigation-checkbox"
          >
            <span className="navigation__overlay-toggle-text">
              Close Menu
            </span>
          </label>
        </div>

        <label
          className="navigation__toggle"
          htmlFor="navigation-checkbox"
        >
          <span className="navigation__toggle-icon" />
          <div className="navigation__toggle-caption">
            <span className="navigation__toggle-text --open">
              Menu
            </span>
            <span className="navigation__toggle-text --close">
              Close
            </span>
          </div>
        </label>

        <div className="navigation__items">
          <div className="navigation__primary">
            <ul className="navigation__list">
              { renderNavLinkItem("Dashboard", router("web.dashboard")) }
              { renderNavLinkItem("Campaigns", router("web.dashboard.campaigns")) }
              { renderNavLinkItem("Fundraisers", router("web.dashboard.fundraisers")) }
              { renderNavLinkItem("Donations", router("web.dashboard.donations", { route: "all" })) }
              { renderNavLinkItem("Reports", router("web.dashboard.reports.index")) }
              { renderNavLinkItem("Support", "https://support.chuffed.org") }

              <li className="navigation__list-item --mobile-only">
                <a className="navigation__item-link" href={router("web.dashboard.profile.index")} data-test="navigation__item-link">
                  Edit Profile
                </a>
              </li>

              <li className="navigation__list-item --mobile-only">
                <a className="navigation__item-link" href="" onClick={logout} data-test="navigation__item-link">
                  <span className="navigation__item-icon">
                    {logoutIcon}
                  </span>
                  Logout
                </a>
              </li>
            </ul>
          </div>

          <div className="navigation__secondary">
            <ul className="navigation__list">
              { renderNavLinkItem("Edit Profile", router("web.dashboard.profile.index")) }
              { renderNavLinkItem("Logout", "", logout) }
              <li className="navigation__list-item --user-nav-item" data-test="user-nav__item">
                {profileAnchor(me)}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardNav;
