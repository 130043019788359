import { jsx as _jsx } from "react/jsx-runtime";
import InstantSearch from 'components/InstantSearch';
import { useState } from 'react';
import { fetch as router } from 'helpers/router';
import LanguageProvider from 'components/LanguageProvider/LanguageProvider';
import useGetTranslationFiles from 'components/LanguageProvider/utils/getTranslationFiles';
const ENTER_KEY = 'Enter';
let timeout;
const InstantSearchContainer = ({ id }) => {
    const { translations } = useGetTranslationFiles();
    const [campaigns, setCampaigns] = useState([]);
    const [searchComplete, setSearchComplete] = useState(false);
    const [queryFiltered, setQueryFiltered] = useState('');
    const [queryInput, setQueryInput] = useState('');
    const [resultCount, setResultCount] = useState(0);
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const fetchCampaigns = (query) => {
        if (query.length < 3) {
            return;
        }
        const route = `${router('api.v2.search.index')}?query=${encodeURIComponent(query)}`;
        clearTimeout(timeout);
        timeout = setTimeout(async () => {
            await fetchData(route);
        }, 300);
    };
    const onQueryChange = (query) => {
        const filteredQuery = query.trim();
        setQueryInput(query);
        setQueryFiltered(filteredQuery);
        fetchCampaigns(filteredQuery);
    };
    const onQueryKeyDown = (event) => {
        if (event.key === ENTER_KEY) {
            fetchCampaigns(queryFiltered);
        }
    };
    const fetchData = (url) => {
        fetch(url, { credentials: 'same-origin' })
            .then((response) => response.json())
            .then((json) => {
            setCampaigns(json.campaigns);
            setSearchComplete(true);
            setLoading(false);
            setResultCount(json.count);
        })
            .catch((error) => {
            setSearchComplete(true);
            setLoading(false);
            setError(true);
            return error;
        });
    };
    return (_jsx(LanguageProvider, { translations: translations, locale: "en", children: _jsx(InstantSearch, { campaigns: campaigns, hasSearched: searchComplete, id: id, isError: isError, isLoading: isLoading, onQueryChange: onQueryChange, onQueryKeyDown: onQueryKeyDown, queryFiltered: queryFiltered, queryInput: queryInput, resultCount: resultCount }) }));
};
export default InstantSearchContainer;
