import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import TextHeading from "../TextHeading";

import "./_style.scss";

const CampaignListing = ({
  children,
  headingText,
  headingUrl,
  isLoading,
  isNarrow,
  isRow2,
  isRow4,
  isSingleItem,
  ...other
}) => (
  <div
    className={
      classNames({
        "campaign-listing": true,
        "--narrow": isNarrow,
        "--row-2": isRow2,
        "--row-4": isRow4,
        "--single-item": isSingleItem,
      })
    }
    {...other}
  >
    {(children.length > 0 && (headingText || headingUrl))
        && (
        <div className="campaign-listing__header">
          {headingText
            && <div className="campaign-listing__heading"><TextHeading level={3} size={7}>{headingText}</TextHeading></div>}
          {headingUrl
            && <a className="campaign-listing__heading-link" href={headingUrl}>More</a>}
        </div>
        )}
    <div className="campaign-listing__list">
      {
        React.Children.map(
          children,
          (child) => React.cloneElement(child, {
            isLoading,
          }),
        )
      }
    </div>
  </div>
);

CampaignListing.propTypes = {
  children: PropTypes.node.isRequired,
  headingText: PropTypes.string,
  headingUrl: PropTypes.string,
  isNarrow: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRow2: PropTypes.bool,
  isRow4: PropTypes.bool,
  isSingleItem: PropTypes.bool,
};

export default CampaignListing;
