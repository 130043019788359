import React from "react";
import classNames from "classnames";

import { format as currencyFormat } from "currency-formatter";
import { imageProfileUrl } from "helpers/imageProfile";

import Skeleton from "react-loading-skeleton";

import "./_style.scss";
import TextTranslator from "components/TranslateText/TextTranslator";

const CampaignCard = (props = {
  currencySymbol: "$",
  fractionalPart: ",00",
}) => {
  const {
    campaign: {
      currency,
      currencySymbol,
      image,
      isActive,
      location,
      moneyRaised,
      ownerName,
      pitch,
      target,
      title,
      url,
      user_image,
    },
    isFeatured,
    isIframe,
    isLoading,
  } = props;
  const amountRaisedDisplay = `${currencySymbol}${Math.round(moneyRaised)}`;
  const percentageRaised = target === 0 ? 100 : moneyRaised / target * 100;
  const avatarImageUrl = user_image !== undefined && user_image !== null && user_image !== "" ? imageProfileUrl(user_image, "campaign.avatar") : null;
  const headerImageUrl = image && imageProfileUrl(image, "campaign.header_desktop");

  return (
    <div className={classNames({
      "campaign-card": true,
      "--featured": isFeatured,
      "--iframe": isIframe,
    })}
    >

      <div className="campaign-card__media">
        <a
          data-test="campaign-card"
          className={classNames("campaign-card__image", {
            "--isLoading": isLoading,
            "--hasLoaded": !isLoading,
          })}
          href={url}
          style={{
            backgroundImage: isLoading ? false : `url(${headerImageUrl})`,
          }}
        >
          {!isLoading && <img className="campaign-card__image-tag" src={headerImageUrl || ""} alt="" />}
        </a>
      </div>
      <div className="campaign-card__content">
        {!isLoading && avatarImageUrl !== null && (
        <div className="campaign-card__profile-wrapper">
          <img className="campaign-card__profile-image" src={avatarImageUrl} alt="" />
        </div>
        )}
        <div className="campaign-card__heading-and-meta">
          <h4 className="campaign-card__heading">
            <a className="campaign-card__heading-link" href={url}>
              {isLoading ? <Skeleton count={3} /> : title}
            </a>
          </h4>
          {ownerName && (
          <div className="campaign-card__owner">
            {isLoading ? <Skeleton count={1} /> : (
              <span>
                <TextTranslator replacements={{ name: ownerName }}>By :name</TextTranslator>
              </span>
            )}
          </div>
          )}
          {location && (
          <div className="campaign-card__location">
            {isLoading ? <Skeleton count={1} /> : location}
          </div>
          )}
        </div>
        {isFeatured && (
        <div className="campaign-card__pitch">
          {isLoading ? <Skeleton /> : pitch}
        </div>
        )}
        <div className="campaign-card__indicator-and-raised">
          <div className={classNames({
            "campaign-card__indicator": true,
            "--completed": !isActive,
          })}
          >
            {!isLoading && (
            <span
              style={{
                width: `${percentageRaised}%`,
              }}
              className={classNames({
                "campaign-card__indicator-bar": true,
                "--completed": !isActive,
              })}
            />
            )}
          </div>
          {isLoading ? <Skeleton /> : (
            <div className="campaign-card__raised">
              <span className="campaign-card__raised-amount">
                {currencyFormat(amountRaisedDisplay, {
                  code: currency,
                  precision: 0,
                })}
              </span>
              {" "}
              <TextTranslator>raised</TextTranslator>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
