import React from "react";
import { render } from "react-dom";
import { client } from "helpers/apolloClientLink";

import { ApolloProvider } from "@apollo/client";
import Navigation from "widgets/Navigation";
import InstantSearchContainer from "./components/InstantSearchContainer";

import "./style.scss";

window.addEventListener("DOMContentLoaded", () => {
  const navRoot = document.getElementById("js-nav-root");
  if (navRoot === null) {
    return;
  }
  render(
    <ApolloProvider client={client}>
      <div>
        <Navigation />
        <InstantSearchContainer
          id="search-toggle-checkbox"
        />
      </div>
    </ApolloProvider>,
    navRoot,
    () => {
      const searchCheckbox = document.getElementById("search-toggle-checkbox");
      searchCheckbox.addEventListener("change", () => {
        if (searchCheckbox.checked === true) {
          document.getElementById("js-instant-search-input").focus();
        }
      });
    },
  );
});
