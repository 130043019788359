import { jsx as _jsx } from "react/jsx-runtime";
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
const TextTranslator = ({ children, replacements }) => {
    const { translate: t } = useLanguageProvider();
    const translatedText = t(children, { ...replacements });
    return (_jsx("span", { children: translatedText }));
};
TextTranslator.defaultProps = {
    replacements: {},
};
export default TextTranslator;
